._3AWTh {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
._3AWTh::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: -1;
  background-color: hsla(0, 0%, 0%, 0.9);
}
._3ZUEV {
  position: absolute;
  top: 2rem;
  right: 3rem;
}
._3bkri {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b61827;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}
._3bkri:hover {
  background-color: #911421;
}
._3bkri:focus {
  box-shadow: 0px 0px 0px 3px #007c91;
}
._20cg_ {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background-color: #ffffff;
  cursor: pointer;
}
._20cg_:hover {
  background-color: rgb(161, 161, 161);
}
._20cg_:focus {
  box-shadow: 0px 0px 0px 3px #007c91;
}
._18xMp {
  position: absolute;
  top: 50%;
  right: 1.5rem;
}
._2pWTS {
  position: absolute;
  top: 50%;
  left: 1.5rem;
}
